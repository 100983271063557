import Navigo from 'navigo';
import { getCurrentWcnpNamespace } from 'common/utils/test.utils';

const router = new Navigo(window.location.origin);

// If running under test, fetch our context, which includes the test user, generated fc id, etc before any routes load.
if (ENV === 'local' || ENV === 'local-cloud') {
  let contextApiUrl: string;
  if (ENV === 'local') {
    contextApiUrl = `http://${LOCAL_TEST_HOST}/_testsuite/context`;
  } else if (ENV === 'local-cloud') {
    const wcnpNamespace = getCurrentWcnpNamespace();
    contextApiUrl = `${window.location.protocol}//${LOCAL_TEST_HOST}.${wcnpNamespace}/_testsuite/context`;
  }

  router.hooks({
    before: done => {
      fetch(contextApiUrl)
        .then(res => {
          console.log(contextApiUrl);
          if (res.ok) {
            return res.json();
          } else {
            const msg = '_testsuite/context: ' + res.statusText;
            document.body.textContent = msg;
            return Promise.reject(msg);
          }
        })
        .then(testContext => {
          window['thorTestContext'] = testContext;
          done();
        });
    },
  });
}

// Android-native will trigger this function for every scan.
// Dispatch event with scanning data to three frameworks.
window['barcodeScannedAndroid'] = (dataString, labelType) => {
  const event = new CustomEvent('barcode-scanned-Android', { detail: { dataString, labelType } });
  document.activeElement.dispatchEvent(event);
};

router
  .on(new RegExp('^(/fc)?/admin(/(FC[0-9]+))?/?$'), (_a, _b, fcId?: string) =>
    import('./admin').then(({ startAdmin }) => {
      startAdmin(fcId);
    })
  )
  .on({
    '/endgame*': function () {
      window.location.href = `${API.ENDGAME_REDIRECT}${encodeURI(
        window.location.pathname
      )}${encodeURI(window.location.search)}`;
    },
    '/m/home': function () {
      import('./mobile-menu').then(({ startFCSelector }) => {
        startFCSelector();
      });
    },
    '/m/:fcId/connectivity-test': function (params: { fcId: string }) {
      import('./mobile-menu').then(({ startConnectivityTest }) => {
        startConnectivityTest(params.fcId);
      });
    },
    '/m/:fcId/fcLandingPage': function (params: { fcId: string }) {
      import('./mobile-menu').then(({ startMobileNav }) => {
        startMobileNav(params.fcId);
      });
    },
    '/:fcId/Fc-Landing': function (params: { fcId: string }) {
      import('./fc-landing').then(({ startDashboardLandingPage }) => {
        startDashboardLandingPage(params.fcId);
      });
    },
    '/:fcId/first-time-sku': function (params) {
      import('./first-time-sku').then(({ startFirstTimeSku }) => {
        startFirstTimeSku(params.fcId);
      });
    },
    '/:fcId/reload': function (params) {
      import('./reload').then(({ startReload }) => {
        startReload(params.fcId);
      });
    },
    '/:fcId/unloadCart': function (params: { fcId: string }) {
      import('./unload-cart').then(({ startUnloadCart }) => {
        startUnloadCart(params.fcId);
      });
    },
    '/m/:fcId/cartBuild': function (params: { fcId: string }) {
      import('./cart-build').then(({ startCartBuild }) => {
        startCartBuild(params.fcId);
      });
    },
    '/:fcId/pick-ticket-status': function (params: { fcId: string }) {
      import('./picking/pt-status').then(({ start }) => {
        start(params.fcId);
      });
    },
    '/:fcId/expiration-dashboard': function (params: { fcId: string }) {
      import('./fresh-dashboard').then(({ startExpirationDashboard }) => {
        startExpirationDashboard(params.fcId);
      });
    },
    '/:fcId/locations': function (params: { fcId: string }) {
      import('./locations').then(({ startLocations }) => {
        startLocations(params.fcId);
      });
    },
    '/delivery-management/:fcId/delivery-detail': function (params: { fcId: string }, query) {
      import('./delivery-management/delivery-detail').then(({ startDeliveryDetail }) => {
        const urlParameters = new URLSearchParams(query);
        startDeliveryDetail(
          params.fcId,
          urlParameters.get('deliveryNum') || '',
          urlParameters.get('source') || ''
        );
      });
    },
    '/delivery-management/:fcId/delivery-search': function (params: { fcId: string }) {
      import('./delivery-management/delivery-search').then(({ startDeliverySearch }) => {
        startDeliverySearch(params.fcId);
      });
    },
    '/delivery-management/:fcId/delivery-history-search': function (
      params: { fcId: string },
      query
    ) {
      import('./delivery-management/delivery-history-search').then(
        ({ startDeliveryHistorySearch }) => {
          const urlParameters = new URLSearchParams(query);
          startDeliveryHistorySearch(params.fcId, urlParameters.get('deliveryNum') || '');
        }
      );
    },
    '/:fcId/problem-solve/self-service': function (params: { fcId: string }) {
      import('./problem-solve/self-service').then(({ startSelfService }) => {
        startSelfService(params.fcId);
      });
    },
    '/:fcId/exit-order-search': function (params: { fcId: string }) {
      import('./exit-inventory-management/exit-order-search').then(({ startExitOrderSearch }) => {
        startExitOrderSearch(params.fcId);
      });
    },
    '/:fcId/exit-order-details/:exitOrderNumber/type/:exitOrderType': function (params: {
      fcId: string;
      exitOrderNumber: string;
      exitOrderType: string;
    }) {
      import('./exit-inventory-management/exit-order-details').then(({ startExitOrderDetails }) => {
        startExitOrderDetails(params.fcId, params.exitOrderNumber, params.exitOrderType);
      });
    },
    '/:fcId/exit-order-reload': function (params: { fcId: string }) {
      import('./exit-inventory-management/exit-order-reload').then(({ startExitOrderReload }) => {
        startExitOrderReload(params.fcId);
      });
    },
    '/:fcId/picking/lane-zones-mapping': function (params: { fcId: string }) {
      import('./picking/lane-zones-mapping').then(({ start }) => {
        start(params.fcId);
      });
    },
    '/:fcId/icqa-dashboard': function (params: { fcId: string }) {
      import('./icqa-dashboard').then(({ start }) => {
        start(params.fcId);
      });
    },
    // startDeliveryHistorySearch
    '/fc/*': () => {
      import('./fc');
    },
    '/retail': () => {
      import('./fc');
    },
    '/retail/*': () => {
      import('./fc');
    },
    '/inventory-planning': () => {
      import('./fc');
    },
    '/inventory-planning/*': () => {
      import('./fc');
    },
    '*': () => {
      import('./deprecating');
    },
  })
  .resolve();
