export function isLocalEnvWithTestContext(window: Window): window is Window & {
  thorTestContext: {
    userId: string;
    fcId: string;
  };
} {
  if (ENV === 'local' || ENV === 'local-cloud') {
    return !!window['thorTestContext'];
  } else {
    return false;
  }
}

export function getCurrentWcnpNamespace() {
  // https://thor-web.[wcnpNamespace]/...
  return window.location.href.replace(/(https?:\/\/)?([^.]+\.)?/, '').split('/')[0];
}
